<template>
    <div class="othercate">
          <Header url="cate"></Header>
      <div class="w12 clearfix">
                  <div class="cateleft">
                         <div class="leftcatettile"><img src="../assets/img/pensl.png" alt=""> 设计图库</div>
                         <ul>
                            <li v-for="(item,i) in cate" :key="i">
                                 <div class="cateline1">
                                   <span class="onecate1" @click="gopage(1,1)">一级分类</span>
                                   <span class="span2" v-for="(item,index) in cate" :key="index" >二级分类</span></div>

                                  <div class="cate2box">
                                      <span v-for="(item,index) in cate" :key="index" @click="gopage(2,1)">二级分类</span>
                                  </div>
                            </li>
                         </ul>

                  </div>
                 <div class="goodsright">
                      <ul class="clearfix" style="padding-top: 25px;">
                        <li  class="item">
                          <div class="goodsinfo" @click="showimg(11)">
                            <img src="../assets/img/11.jpg" alt="">
                          </div>
                          <div class="imgtitle line1">西红柿炒鸡蛋  <span @click="gocart()">+</span></div>
                        </li>
                        <li  class="item">
                          <div class="goodsinfo" @click="showimg(22)">
                            <img src="../assets/img/22.jpg" alt="">
                          </div>
                          <div class="imgtitle line1">西红柿炒鸡蛋  <span @click="gocart()">+</span></div>
                        </li>
                        <li  class="item">
                          <div class="goodsinfo" @click="showimg(33)">
                            <img src="../assets/img/33.jpg" alt="">
                          </div>
                          <div class="imgtitle line1">西红柿炒鸡蛋  <span @click="gocart()">+</span></div>
                        </li>
                        <li  class="item" v-for="(item,index) in goodslist" :key="index">
                          <div class="goodsinfo" @click="showimg(1)">
                            <img src="../assets/img/1.png" alt="">
                          </div>
                          <div class="imgtitle line1">西红柿炒鸡蛋  <span @click="gocart()">+</span></div>
                        </li>
                      </ul>
                 </div>
      </div>
      <Img  v-if="img.flag"  :src="img.src" @closeImg="closeImgFun"/>
      <Msg  v-if="msg.flag" :msg="msg"/>
          <Footer />
    </div>
</template>
<style>
.othercate{background: #f5f5f5}
.othercate .cateleft{width: 300px;float: left;margin: 30px 0;    border:1px solid #fad5e8;background-color: #fcf1f7;}
.othercate .cateleft .leftcatettile{background-color: #f8e1ed;padding: 16px;  font-size: 17px;color: #ae025d}
.othercate .cateleft .leftcatettile img{    width: 24px;  vertical-align: middle;}
.othercate .cateleft ul li{width: 280px;  padding:5px; margin: 5px; line-height: 20px;    border-top: 1px solid #f1d7e5; }
.othercate .cateleft ul li .cateline1{overflow: hidden;   height: 28px; padding-top: 6px }
.othercate .cateleft ul li span{margin: 2px 0;padding:2px 10px;  display: inline-block;  white-space: nowrap;font-size: 12px;color: #333;cursor: pointer}
.othercate .cateleft ul li span.onecate1{font-weight: bold}
.othercate .cateleft ul li span.onecate1:hover{font-weight: bold;color: #f0419b}

.othercate .cateleft ul li .cate2box{display: none;}
.othercate .cateleft ul li:hover{background-color: #f8e1ed;   border-radius: 4px;}
.othercate .cateleft ul li:hover .span2{display: none}
.othercate .cateleft ul li:hover .cate2box{display: block;padding-top:8px}
.othercate .cateleft ul li:hover .cate2box span:hover{    background-color: #f0419b;color: #fff;border-radius: 4px}


.othercate  .goodsright{    margin-left: 300px;margin-top: 60px}
.othercate  .goodsright .item{position: relative;width:180px;float: left;margin: 5px;  display: inline-block;  background-color: #fff;  border: 1px solid #fff}
.othercate  .goodsright .item:hover{border: 1px solid #ccc}
.othercate  .goodsright .item .goodsinfo{width:180px;height:182px;  cursor: pointer;  display: table-cell;  vertical-align: middle;text-align: center;  }
.othercate  .goodsright .item img{max-width:180px;max-height:180px;width: auto;height: auto}
.othercate  .goodsright .item .imgtitle{position: relative;padding: 10px;  background: #fff;  border-top: 1px solid #dfdfe3;}
.othercate  .goodsright .item .imgtitle span{cursor:pointer;text-align:center;position: absolute;right: 5px;top:6px;color: #1ebcf0;border: 1px solid #1ebcf0;border-radius: 50%;    width: 20px;
  height: 20px;
  line-height: 20px;}
</style>
<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
import Msg from '@/components/msg'
import Img from '@/components/img'
export default {
    name: 'Cate',
    components:{Header,Footer,Msg,Img},
    data() {
        return {
          cate:[1,2,3,4,5,6,1,2,3,4,5,6,1,2,3,4,5,6,1,2,3,4,5,6],
          goodslist:[1,2,3,4,5,6,1,2,3,4,5,6,1,2,3,4,5,6,1,2,3,4,5,6],
          msg:{
            flag:false,
            msg:'',
            icon:false
          },
          img:{
            flag:false,
            src:''
          }
        }
     },
    mounted(){ },
    methods: {
      gocart(){
        let that = this;
        this.msg.flag = true;
        this.msg.msg = '加入成功';
        setTimeout(function(){
          that.msg.flag = false;
        },600)
      },
      gopage(type,id){
          if(type==1){ this.$router.push({path:'/cate2',query:{cid:id}});}
          else { this.$router.push({path:'/goodsList',query:{cid:id}});}

      },
      showimg(src){
        // src = 'http://pic.ntimg.cn/file/20210331/18549423_125448972031_2.jpg'
        this.img.flag = true;
        this.img.src = src;
      },
      closeImgFun(e){
        console.log(e)
        this.img.flag = false;
        this.img.src = '';
      }
    }
}
</script>
